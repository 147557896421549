import { DxTabPanel, DxItem } from 'devextreme-vue/ui/tab-panel';
import { defineComponent, defineAsyncComponent } from 'vue';

export default defineComponent({
  name: 'IpiTabs',
  components: {
    DxTabPanel,
    DxItem,
    Ipi: defineAsyncComponent(() => import('./ipi/index.vue')),
    TotaisPorCst: defineAsyncComponent(() => import('./totais-por-cst/index.vue')),
    NcmxCstIpi: defineAsyncComponent(() => import('./ncm-x-cst-ipi/index.vue'))
  }
})
<template>
  <div>
    <DxTabPanel
      height="100%"
      :show-nav-buttons="true"
      :repaint-changes-only="true"
      :animation-enabled="true"
    >
      <DxItem title="IPI">
        <template #default>
          <Ipi />
        </template>
      </DxItem>
      <DxItem title="Totais Por CST">
        <template #default>
          <TotaisPorCst />
        </template>
      </DxItem>
      <DxItem title="NCM X CST IPI">
        <template #default>
          <NcmxCstIpi />
        </template>
      </DxItem>
    </DxTabPanel>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>